export default (recordType: any, attributeId: any): any => {
    return {
        endpoint: '/search/',
        page_list: [],
        current_page: null,
        data: '',
        search (q: any, page: any) {
            fetch(this.endpoint, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                  'X-CSRFToken': window.CSRF_TOKEN,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ q: q, recordType: recordType, attributeId: attributeId, page: page })
              })
              .then(async response => await response.json())
              .then(response => {
                this.data = response.result
                this.page_list = response.page_list
                this.current_page = response.current_page
              })
              .catch(error => {
                  this.error_message = 'An error has occurred: ' + String(error)
              })
        }
    }
}
